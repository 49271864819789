import styled from '@emotion/styled'
import { Faq } from 'app/components/Faq'
import { RoomsPreview } from 'app/components/RoomsPreview'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function InfoPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <SEO {...context.seoProps} />
        {context.structuredDataProps ? (
          <StructuredData
            languageCode={pageContext.languageCode}
            {...context.structuredDataProps}
          />
        ) : null}
        {context.headerProps ? (
          <Header
            pageID={context.id}
            languageCode={context.languageCode}
            languagePrefix={context.languagePrefix}
            variant="compact"
            {...context.headerProps}
          />
        ) : null}
        {context.faqProps ? <Faq {...context.faqProps} /> : null}
        <Media lessThan="ipadHorizontal">
          {context.roomsPreviewProps ? (
            <RoomsPreview {...context.roomsPreviewProps} />
          ) : null}
        </Media>
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            {...context.footerProps}
          />
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.main``
